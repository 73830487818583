@import url(https://fonts.googleapis.com/css?family=Chakra+Petch);

:root {
  /* Set neon color */
  --neon-text-color: aliceblue;
  --neon-text-color2: #222e4c;
  --neon-text-color3: #2b3a61;
  --neon-border-color: #08f;
}

#neon-span {
  font-size: 32px;
  font-weight: 200;
  font-style: italic;
  color: aliceblue;
  padding: 4px 6px 5.5px;
  animation: flicker 2s infinite alternate;     
}

#neon-span-small {
    font-size: 24px;
    font-weight: 200;
    font-style: italic;
    color: aliceblue;
    padding: 4px 6px 5.5px;
    animation: flicker-small 2s infinite alternate;
  }

  #neon-span-small-dark {
    font-size: 24px;
    font-weight: 200;
    font-style: italic;
    color: #2b3a61;
    padding: 4px 6px 5.5px;
    animation: flicker-small-dark 2s infinite alternate;
  }

  #neon-span-large {
    font-size: 134px;
    font-weight: 200;
    font-style: italic;
    color: #222e4c;
    padding: 4px 6px 5.5px;
    animation: flicker-large 2s infinite alternate;
  }


#neon-span::-moz-selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

#neon-span::selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

#neon-span:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
        text-shadow:
            -0.5px -0.5px 2px aliceblue,
            0.5px 0.5px 2px aliceblue,
            0 0 2px var(--neon-text-color),
            0 0 4px var(--neon-text-color),
            0 0 6px var(--neon-text-color),
            0 0 8px var(--neon-text-color),
            0 0 10px var(--neon-text-color);
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
        box-shadow: none;
    }    
}

@keyframes flicker-small {
    
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
        text-shadow:
            -0.3px -0.3px 1px aliceblue,
            0.3px 0.3px 1px aliceblue,
            0 0 2px var(--neon-text-color),
            0 0 4px var(--neon-text-color),
            0 0 6px var(--neon-text-color),
            0 0 8px var(--neon-text-color),
            0 0 10px var(--neon-text-color);
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
        box-shadow: none;
    }    
}

@keyframes flicker-small-dark {
    
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    
      text-shadow:
          -0.3px -0.3px 1px #2b3a61,
          0.3px 0.3px 1px #2b3a61,
          0 0 2px var(--neon-text-color3),
          0 0 4px var(--neon-text-color3),
          0 0 6px var(--neon-text-color3),
          0 0 8px var(--neon-text-color3),
          0 0 10px var(--neon-text-color3);
  }
  
  20%, 24%, 55% {        
      text-shadow: none;
      box-shadow: none;
  }    
}


@keyframes flicker-large {
    
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    
      text-shadow:
          -1.0px -1.0px 20px #222e4c,
          1.0px 1.0px 20px #222e4c,
          0 0 2px var(--neon-text-color2),
          0 0 4px var(--neon-text-color2),
          0 0 6px var(--neon-text-color2),
          0 0 8px var(--neon-text-color2),
          0 0 10px var(--neon-text-color2);
  }
  
  20%, 24%, 55% {        
      text-shadow: none;
      box-shadow: none;
  }    
}