
body {
  overflow: overlay;
  background-color: #0F1629;
  /* overflow-y: hidden; */
}


.App {
  text-align: center;
  height: 100%;
  font-family: Chakra Petch;
  background-color: #0F1629;
}

.history-cell {
  /* background-color: #000000; */
  border-radius: 0px;
  width: fit-content;
}
.table-on-hover > td, .table-on-hover > tr {
  background-color: #000000;
  border-radius: 0px;
}
.table-on-hover:hover > td {
  background-color: #0F1629;
}

#history-table-wrapper > table {
  /* border: 4px solid #2b3a61; */
  /* border-radius: 10px; */
  border-spacing: 0px;
  width: 100%;
}

th {
  border-radius: 5px;
  padding: 7px;
  /* font-size: 20px; */
}

td {
  max-width: 600px;
  padding: 7px;
  border-radius: 5px;
  /* overflow-y: scroll; */
}

.data-dt {
  max-width: 500px;
  padding: 7px;
  border-radius: 5px;
  overflow-y: scroll;
}

.data-dt::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

@media screen and (max-width: 550px) {
  td {
    font-size: 450px;
  }
  }
  @media screen and (max-width: 450px) {
    td {
      font-size: 350px;
    }
  }


div.space-even-spans {
  padding-top: 20px;
  display: table;
  width: 100%;
  table-layout: fixed;    /* For cells of equal size */
}
div.space-even-spans div {
  display: table-cell;
  /* text-align: center; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #222e4c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2b3a61;
}

::-webkit-scrollbar-corner {
  background: #2b3a61;
}

tr:nth-child(even) {
  background-color: #2b3a61;
}
tr:nth-child(even) > ::-webkit-scrollbar-corner {
  background: #2b3a61;
}
tr:nth-child(even) > ::-webkit-scrollbar-thumb {
  background: #222e4c;
  border-radius: 5px;
}
tr:nth-child(even) > ::-webkit-scrollbar-thumb:hover {
  background: #222e4c;
}




tr:nth-child(odd) {
  background-color: #222e4c;
}
tr:nth-child(odd) > ::-webkit-scrollbar-corner {
  background-color: #222e4c;
}
tr:nth-child(odd) > ::-webkit-scrollbar-thumb {
  background: #2b3a61;
  border-radius: 5px;
}
tr:nth-child(odd) > ::-webkit-scrollbar-thumb:hover {
  background: #2b3a61;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: black;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #222e4c;
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #2b3a61;
} */

/* ::-webkit-scrollbar-corner {
  background: #2b3a61;
} */

.content {
  margin: 0;
  position: absolute;
  width: 100%;
  height: 50%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /* padding-bottom: 15px; */
  /* overflow-y: scroll; */
}

.content-wrapper {
    overflow-y: scroll;
}

.head-text {
  /* padding-top: 40%; */
  color: #222e4c;
  font-size: 26px;
  /* font-size: 5vw; */
}

.ip-address {
  font-size: 92px;
  /* font-size: 7.4rem; */
  /* font-size: 5vw; */
  
}
@media screen and (max-width: 550px) {
  .ip-address {
    font-size: 65px;
  }
  }
  @media screen and (max-width: 430px) {
    .ip-address {
      font-size: 50px;
    }
  }

footer {
  position: fixed;
  /* background-color: rgb(15, 22, 41, 0.0); */
  /* background-color: #0F1629; */
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  text-align: center;
  color: #222e4c;
  font-size: 14px;
}

.modal-footer {
  background-color: rgb(15, 22, 41, 0.0);
}

header {
  position: fixed;
  background-color: rgb(15, 22, 41, 0.0);
  /* background-color: #0F1629; */
  top: 0;
  /* left: 0; */
  right: 0;
  padding-top: 10px;
  padding-right: 15px;
  text-align: center;
  color: #222e4c;
  font-size: 24px;
  /* z-index: 1; */
}

.header {
  position: fixed;
  background-color: #0F1629;
  /* background-color: #0F1629; */
  top: 0;
  /* left: 0; */
  /* right: 0; */
  padding-top: 10px;
  padding-right: 15px;
  text-align: center;
  color: #222e4c;
  font-size: 24px;
  /* z-index: 1; */
  z-index: 9999999;
  height: 45px;
  width: 100%;
  /* border-bottom: 3px solid; */
}

/* .main-header {
  background-color: #0F1629;
  z-index: 1;
} */


.header-left {
  position: fixed;
  background-color: rgb(15, 22, 41, 0.0);
  /* background-color: #0F1629; */
  top: 0;
  left: 0;
  /* right: 0; */
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 15px;
  text-align: center;
  color: #222e4c;
  font-size: 24px;
  /* z-index: 1; */
}
.header-center {
  position: fixed;
  background-color: rgb(15, 22, 41, 0.0);
  top: 0;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 15px;
  text-align: center;
  color: #222e4c;
  font-size: 24px;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}

.user-agent {
  position: fixed;
  background-color: rgb(15, 22, 41, 0.0);
  top: 0;
  left: 0;
  right: 0;
  padding: 5px;
  text-align: center;
  color: #222e4c;
  font-size: 16px;
}


a:link {
  color: aliceblue;
  background-color: transparent;
  text-decoration: underline;
}

a:visited {
  color: aliceblue;
  background-color: transparent;
  text-decoration: underline;
}

a:hover {
  color: rgb(159, 179, 197);
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: aliceblue;
  background-color: transparent;
  text-decoration: underline;
}

.dark-a:link {
  color: #222e4c;
  background-color: transparent;
  text-decoration: underline;
}

.dark-a:visited {
  color: #222e4c;
  background-color: transparent;
  text-decoration: underline;
}

.dark-a:hover {
  color: #2b3a61;
  background-color: transparent;
  text-decoration: underline;
}

.dark-a:active {
  color: #222e4c;
  background-color: transparent;
  text-decoration: underline;
}


.scroll {
    max-height: 400px;
    overflow-y: scroll;
}

.scroll-console {
  max-height: 600px;
  overflow-y: scroll;
}


.leaflet-container {
  width: 100%;
  height: 75vh;
}

#map {
  border-radius: 13px;
  overflow: hidden;
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
      filter:var(--map-tiles-filter, none);
}
}


.bg-tab {
  background-color: aqua;
}

.react-tabs {
  padding-bottom: 7px;
}

.loader {
  width: 48px;
  height: 40px;
  margin-top: 30px;
  display: inline-block;
  position: relative;
  background: aliceblue;
  border-radius: 15% 15% 35% 35%;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 45px;
  top: 8px;
  border: 4px solid aliceblue;
  width: 16px;
  height: 20px;
  border-radius: 0 4px 4px 0;
}
.loader::before {
  content: '';  
  position: absolute;
  width: 1px;
  height: 10px;
  color: aliceblue;
  top: -15px;
  left: 11px;
  box-sizing: border-box;
  animation: animloader 1s ease infinite;
}

@keyframes animloader {
    0% {
  box-shadow: 2px 0px rgba(255, 255, 255, 0), 12px 0px rgba(255, 255, 255, 0.3), 20px 0px rgba(255, 255, 255, 0);
}
    50% {
  box-shadow: 2px -5px rgba(255, 255, 255, 0.5), 12px -3px rgba(255, 255, 255, 0.5), 20px -2px rgba(255, 255, 255, 0.6);
}
    100% {
  box-shadow: 2px -8px rgba(255, 255, 255, 0), 12px -5px rgba(255, 255, 255, 0), 20px -5px rgba(255, 255, 255, 0);
}
  }



.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-200px);
  /* transition: all 500ms ease-in-out; */
  animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: 225ms;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  transform: translateX(-200px);
  opacity: 0;
}


  .loader-refresh {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }

    #map > div > div.leaflet-control-container > div.leaflet-bottom.leaflet-right > div > a {
      color: black;
    }

    #map > div > div.leaflet-control-container > div.leaflet-bottom.leaflet-right > div > a:hover {
      color: #2b3a61;
    }


    .input {
      color: aliceblue;
      max-width: 220px;
      min-width: 220px;
      font-size: 20px;
      background-color: rgb(15, 22, 41, 0.0);
      border: 0px;
      border-bottom: 2px solid #222e4c;
      padding-bottom: 5px;
      /* caret-color: #222e4c; */
      caret-color: aliceblue;
      outline: none;
      font-family:"Chakra Petch";
    }

    .input:focus {
      border-bottom: 2px solid #2b3a61;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #222e4c;
      opacity: 1; /* Firefox */
      padding-left: 4px;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #222e4c;
      padding-left: 4px;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #222e4c;
      padding-left: 4px;
    }

    /* td:last-child {
      text-align: right;
      justify-content: right;
    } */


    hr {
      /* background-color: #222e4c;
      color: #222e4c;
      border-color: #222e4c; */
    }

    .hr-popup {
      background-color: #2b3a61;
      color: #2b3a61;
      border-color: #2b3a61;
      border: 1px solid #2b3a61;
    }

    #popup-2 {
      width: fit-content;
  }


  svg:not(:root) > path {
    /* overflow-clip-margin: content-box; */
    overflow: hidden;
    fill: #2b3a61;
}


#toggle-whois > div > div.react-toggle-track > div.react-toggle-track-x > svg > path {
  fill: #0F1629;
}
#toggle-whois > div > div.react-toggle-track > div.react-toggle-track-check > svg > path {
  fill: #0F1629;
}

#toggle-dns > div > div.react-toggle-track > div.react-toggle-track-x > svg > path {
  fill: #0F1629;
}
#toggle-dns > div > div.react-toggle-track > div.react-toggle-track-check > svg > path {
  fill: #0F1629;
}

#toggle-ip-area > div > div.react-toggle-track > div.react-toggle-track-x > svg > path {
  fill: #0F1629;
}
#toggle-ip-area > div > div.react-toggle-track > div.react-toggle-track-check > svg > path {
  fill: #0F1629;
}

/* .react-toggle--checked .react-toggle-thumb {
  left: 27px;
  background-color: #19AB27;
} */

/* .react-toggle--checked {
  background-color: #5CDD8A;
  color: #5CDD8A;
  fill: #5CDD8A;
} */

    .popup-content {
      color: aliceblue;
      margin: auto;
      background: #000000;
      width: 50%;
      min-width: fit-content;
      padding: 5px;
      border: 2px solid #2b3a61;
      border-radius: 10px;
      z-index: 999999999 !important;
      display: inline !important;
    }
    .popup-arrow {
      color: #2b3a61;
      /* background-color: #2b3a61;
      border-color: #2b3a61; */
    }
    [role='tooltip'].popup-content {
      width: 200px;
      /* color: #2b3a61; */
      box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
      /* position: absolute;
      top: 0;
      overflow-y: hidden; */
    }
    
    .popup-overlay {
      background: rgba(0, 0, 0, 0.5);
      /* position: absolute;
      top: 0;
      overflow-y: hidden; */
    }
    [data-popup='tooltip'].popup-overlay {
      /* background: rgba(0, 0, 0, 0.0); */
      background: transparent;
      /* position: absolute;
      top: 0;
      overflow-y: hidden; */
    }


@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}


#main-header {
  background-color: #0F1629;
  z-index: 999;
}



.ErrorCounter {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #158C15;
  /* color: #ec2941; */
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  line-height: 24px;
}

.ErrorCounter::after {
  content: "";

  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  display: inline-block;
  width: 24px;
  height: 24px;

  background-color: #19AB27;
  border-radius: 50%;
  animation: pulse 1000ms cubic-bezier(0.25, 0.1, 0.25, 0.1) infinite;
  animation-fill-mode: forwards;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}










.deskanimation {
  width: 256px;
  height: 256px;
}

/* SMOKE */
#smoke-1 {
  stroke-dasharray: 0, 10;
  animation: smoke 6s ease infinite;
}

#smoke-2 {
  stroke-dasharray: 0, 10;
  animation: smoke 6s 0.5s ease infinite;
}

@keyframes smoke {
  0% { stroke-dasharray: 0, 10; }
  50% { stroke-dasharray: 10, 0; }
  100% { stroke-dasharray: 10, 0; opacity: 0; }
}

/* WRITING */
#line-1 {
  opacity: 0;
  animation: writing 0.5s linear forwards;
}

#line-2 {
  opacity: 0;
  animation: writing 0.5s 1s linear forwards;
}

#line-3 {
  opacity: 0;
  animation: writing 0.5s 1.5s linear forwards;
}

#line-4 {
  opacity: 0;
  animation: writing 0.5s 2s linear forwards;
}

@keyframes writing {
  0% { width: 0px; opacity: 1;}
  100% { width: 14px; opacity: 1;}
}


.openCheckmarkSpanWrap {
  padding-right: 15px;
}
.openCheckmarkSpan {
  border: 2px solid;
  border-radius: 25px;
  width: 21px;
  display: inline-block;
}
.openCheckmarkSpan:hover {
  border: 2px solid #0e5f0e;
}

.closeCheckmarkSpanWrap {
  padding-left: 15px;
}
.closeCheckmarkSpan {
  color: #ec2941;
  border: 2px solid;
  border-radius: 25px;
  width: 21px;
  display: inline-block;
}
.closeCheckmarkSpan:hover {
  border: 2px solid #931625;
}


.rotate-text-90 {
  transform: rotate(95deg);


  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(95deg);

  /* Firefox */
  -moz-transform: rotate(95deg);

  /* IE */
  -ms-transform: rotate(95deg);

  /* Opera */
  -o-transform: rotate(95deg);
}




.dashed > li {
  list-style-type: none;
  /* padding-left: 10px; */
}
.dashed > li:before {
  content: '\2014';
  margin-left: -30px;
  margin-right: 8px;
}

.button-hover-cookies {
  background-color: #222e4c;
}
.button-hover-cookies:hover {
  background-color: #f01932;
}


.online-span {
  color: #19AB27;
}
.online-span:hover {
  color: #11711b;
}


/* .react-tabs__tab-list > li {
  background-color: #148414;
  border-color: #bc1f31;
} */

.react-tabs__tab:focus:after {
  background: black !important;
}

.react-tabs__tab-list {
  border-bottom: 1.25px solid aliceblue !important;
}

.react-tabs__tab--selected {
  background: aliceblue !important;
  border-color: aliceblue !important;
  border: 0px;
  font-weight: 600;
}


.http-res-loader {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  display: inline-grid;
}
.http-res-loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 2px solid #e8bb2f;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}